export const FirebaseConfig = {
	"projectId": "woyociv",
	"appId": "1:100654653991:web:dff59936131a9db6066402",
	"databaseURL": "https://woyociv-default-rtdb.firebaseio.com",
	"storageBucket": "woyociv.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyAXl6pw6Oaz9SXf3hshiQZ7x4Mb1swtiPw",
	"authDomain": "woyociv.firebaseapp.com",
	"messagingSenderId": "100654653991",
	"measurementId": "G-BEXSNKMXK4"
};